import { ShimState } from 'state-domains/index';
import { isCompleteSelector, isFailedSelector, isPendingSelector } from 'state-domains/utils';
import { LOAD_STATUS_STALE } from 'state-domains/constants';
import { ExportTemplate } from 'state-domains/domain/subscription';
import { createSelector } from 'reselect';
import { AsyncState, AsyncStateError } from 'state-domains/types';

import { DataState, ExportTemplateState } from '../types';

const dataState = (state: Partial<ShimState>): DataState => {
    const { data: jsData = {} as DataState } = state || {};
    return jsData;
};

const exportTemplatesState = createSelector(
    dataState,
    ({
        exportTemplatesState = {
            items: [],
            status: LOAD_STATUS_STALE,
            error: null,
            actionState: {
                status: LOAD_STATUS_STALE,
                error: null,
            },
        },
    }: DataState): ExportTemplateState => exportTemplatesState,
);

const exportTemplates = createSelector(
    exportTemplatesState,
    ({ items = [] }: ExportTemplateState): ExportTemplate[] => items,
);

const exportTemplatesActionState = createSelector(
    exportTemplatesState,
    ({ actionState }: ExportTemplateState): AsyncState => actionState,
);

const exportTemplatesActionStateError = createSelector(
    exportTemplatesState,
    ({ actionState }: ExportTemplateState): AsyncStateError => actionState?.error ?? {},
);

const exportTemplatesActionStateId = createSelector(
    exportTemplatesState,
    ({ actionState }: ExportTemplateState): string => actionState?.id ?? '',
);

export const selectors = {
    dataState,
    exportTemplatesState,
    exportTemplates,
    exportTemplatesActionState,
    exportTemplatesActionStateError,
    exportTemplatesActionStateId,
    isExportTemplatesPending: isPendingSelector(exportTemplatesState),
    isExportTemplatesComplete: isCompleteSelector(exportTemplatesState),
    isExportTemplatesFailed: isFailedSelector(exportTemplatesState),
    isExportTemplatesActionStatePending: isPendingSelector(exportTemplatesActionState),
    isExportTemplatesActionStateComplete: isCompleteSelector(exportTemplatesActionState),
    isExportTemplatesActionStateFailed: isFailedSelector(exportTemplatesActionState),
};
